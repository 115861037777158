<template>
  <div
    class="h-full bg-gray-800 items-center flex flex-col justify-center"
    :class="{ 'min-h-screen': !preview_mode }"
  >
    <div
      class="
        py-5
        mt-12
        w-11/12
        max-w-full
        bg-white
        rounded-lg
        text-center
        sm:max-w-lg
        items-center
        border border-gray-200
      "
    >
      <div v-if="subscriber">
        <!-- Business Logo -->
        <div v-if="businessLogo" class="flex justify-center items-center">
          <img class="h-10" :src="businessLogo" />
        </div>
        <div v-else class="flex justify-center">
          <app-text-icon :text="business?.name" icon_outline="BriefcaseIcon" />
        </div>

        <hr class="mt-3" />

        <!-- Test Mode -->
        <div class="flex justify-center w-full px-8 pt-4">
          <app-test-mode-badge :testMode="testMode" />
        </div>

        <!-- Subscription Details -->
        <div class="text-left p-4 space-y-2">
          <p class="text-xs">
            {{ $t("subscription.activation.subscription_details") }}
          </p>
          <div v-if="loading">
            <paragraph-shimmer
              :is-loading="true"
              :lines="1"
              class="w-28"
              :random-size="true"
            />
            <paragraph-shimmer
              :is-loading="true"
              :lines="1"
              class="w-36"
              :random-size="true"
            />
            <paragraph-shimmer
              :is-loading="true"
              :lines="1"
              class="w-24"
              :random-size="true"
            />
          </div>
          <div class="space-y-2" v-else>
            <p class="font-medium">
              {{
                !$lodash.isEmpty(subscriber.name)
                  ? subscriber.name
                  : "Subscriber Name"
              }}
            </p>
            <p class="text-gray-500">
              {{
                !$lodash.isEmpty(subscriber.email)
                  ? subscriber.email
                  : "Email Address"
              }}
            </p>
            <p class="text-gray-500">{{ subscriber.phone_no }}</p>
          </div>
        </div>

        <!-- Package Details -->
        <div class="text-left p-4 bg-gray-50 border-t border-b">
          <div class="flex flex-wrap justify-between items-center">
            <div v-if="loading">
              <paragraph-shimmer
                :is-loading="true"
                :lines="1"
                class="w-28"
                :random-size="true"
              />
            </div>
            <div v-else>
              <p class="font-medium mr-3">
                {{
                  !$lodash.isEmpty(subscriber.package?.name)
                    ? subscriber.package?.name
                    : "Package Name"
                }}
              </p>
            </div>
            <div v-if="loading">
              <paragraph-shimmer
                :is-loading="true"
                :lines="1"
                class="w-28"
                :random-size="true"
              />
            </div>
            <div v-else>
              <p class="font-medium text-right">
                {{
                  $formats.currency(
                    subscriber.package?.currency ?? "MYR",
                    subscriber.package?.amount ?? 0
                  )
                }}{{
                  !$lodash.isEmpty(subscriber.package?.frequency)
                    ? "/" + subscriber.package?.frequency
                    : ""
                }}
              </p>
            </div>
          </div>
        </div>

        <div class="text-left p-5 bg-gray-50 border-t border-b space-y-2">
          <p>{{ $t("subscription.package.package_term") }}</p>
          <div class="flex flex-wrap justify-between text-lg">
            <div v-if="loading">
              <paragraph-shimmer
                :is-loading="true"
                :lines="1"
                class="w-28"
                :random-size="true"
              />
            </div>
            <div v-else>
              <p v-if="subscriber.package?.frequency_num" class="font-medium mr-3">
                {{ subscriber.package?.frequency_num }}
                {{ packageTerm(subscriber.package) }}
              </p>
              <p v-else class="font-medium mr-3">
                {{ $t("subscription.package.package_term_placeholder") }}
              </p>
            </div>
            <div v-if="loading">
              <paragraph-shimmer
                :is-loading="true"
                :lines="1"
                class="w-28"
                :random-size="true"
              />
            </div>
            <div v-else>
              <p class="font-medium text-right">
                {{
                  $formats.currency(
                    subscriber.package?.currency ?? "MYR",
                    getTotalAmount(subscriber.package) ?? 0
                  )
                }}
              </p>
              <p v-if="subscriber.package?.frequency_num" class="text-gray-400 text-sm">
                {{ subscriber.package?.frequency_num }}
                {{ $t("subscription.package.billing_period") }}
              </p>
              <p v-else class="text-gray-400 text-sm">
                {{ $t("subscription.package.no_of_billing_period") }}
              </p>
            </div>
          </div>
        </div>

        <!-- Amount To Pay -->
        <div class="text-left p-4 space-y-2">
          <p class="text-gray-500 text-sm">
            {{ $t("subscription.package.price_per_billing") }}
          </p>
          <div v-if="loading">
            <paragraph-shimmer
              :is-loading="true"
              :lines="1"
              class="w-28"
              :random-size="true"
            />
          </div>
          <div v-else>
            <p class="text-2xl font-medium">
              {{
                $formats.currency(
                  subscriber.package?.currency ?? "MYR",
                  subscriber.package?.amount ?? 0
                )
              }}
            </p>
          </div>
        </div>

        <!-- Subscription button -->
        <div class="px-5 pt-3">
          <app-button
            type="submit"
            class="w-full"
            @click="proceedSubscribe"
            :disabled="!subscriber._id"
          >
            {{ $t("subscription.activation.subscription_button") }}
          </app-button>
        </div>
      </div>
      <div v-else>{{ $t("general.no_results") }}</div>
    </div>
    <div class="py-8">
      <app-logo-power-by />
    </div>
  </div>
</template>

<script>
import { ParagraphShimmer } from "vue3-shimmer";
import PACKAGE_FREQUENCY from "@/utils/const/package_frequency";

export default {
  components: {
    ParagraphShimmer,
  },
  data() {
    return {
      testMode: false,
      business: null,
    };
  },
  computed: {
    subscriber() {
      return this.$store.getters["subscriptionStore/subscriber"];
    },
    loading() {
      return this.$store.getters["subscriptionStore/loading"];
    },
    packages() {
      return this.$store.getters["subscriptionStore/package"];
    },
    businessLogo() {
      return this.business?.media?.find(
        (media) => media.collection_name == "logo"
      )?.original_url;
    },
  },
  props: {
    preview_mode: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    if (this.$route.params.subscriber_id == null) {
      this.testMode = this.$store.getters["businessStore/test_mode"] ?? false;
      await this.getDataForPreview();
      return;
    }

    if (this.$route.query.test != null && this.$route.query.test == "true") {
      this.testMode = true;
    }

    this.$store.commit("businessStore/setTestModePublicPage", this.testMode);

    await this.$store.dispatch(
      "subscriptionStore/getSubscriberPublic",
      this.$route.params.subscriber_id
    );

    this.business = this.subscriber?.package?.business;
  },

  methods: {
    async proceedSubscribe() {
      if (this.subscriber.proceed_activation_url == null) {
        return;
      }

      window.location.href = this.subscriber.proceed_activation_url;
    },

    async getDataForPreview() {
      let result = await this.$store.dispatch(
        "businessStore/getBusinessFromSelectedRoleBusinessId"
      );

      this.business = result.data;
    },

    getTotalAmount(data) {
      return data.amount * data.frequency_num;
    },

    packageTerm(data) {
      switch (data.frequency) {
        case PACKAGE_FREQUENCY.DAILY:
          return data.frequency_num > 1 ? "days" : "day";
        case PACKAGE_FREQUENCY.WEEKLY:
          return data.frequency_num > 1 ? "weeks" : "week";
        case PACKAGE_FREQUENCY.MONTHLY:
          return data.frequency_num > 1 ? "months" : "month";
        case PACKAGE_FREQUENCY.YEARLY:
          return data.frequency_num > 1 ? "years" : "year";
      }
    },
  },
};
</script>